import useResponsive from "@helpers/useResponsive";
import React from "react";
import { LayoutProps } from "../interface";
import Desktop from "./lib/Desktop/Desktop";
import Mobile from "./lib/Mobile/Mobile";

const LoginPageBaselayout: React.FC<LayoutProps> = (props) => {
  const isMobile = useResponsive("sm");
  const Component = isMobile ? Mobile : Desktop;
  return (
    <Component {...props} />
  );
};

export default LoginPageBaselayout;
