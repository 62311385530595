import { appFirebase } from "./config";
import { getAnalytics, setUserProperties, Analytics, logEvent } from "firebase/analytics";

let analytics: Analytics;

if (typeof window !== "undefined") {
  analytics = getAnalytics(appFirebase);
}

export const setProperty = async (property: string, value: string) => {
  setUserProperties(analytics, { [property]: value });
};

export const logScreen = (pathname: string) => {
  logEvent(analytics, "screen_view", {
    firebase_screen: pathname,
    firebase_screen_class: pathname,
  });
};

export default { setProperty, logScreen };
