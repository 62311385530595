import { bgLogin } from "@config/images";
import { css } from "@emotion/react";

export const imageBackground = css({
  height: "100vh",
  backgroundImage: `url("${bgLogin}")`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
  overflow: "hidden",
});

export const titleText = css({
  fontFamily: "Open Sans, sans-serif",
  fontSize: "24px",
  color: "#2E434D",
  lineHeight: "34.8px",
  fontWeight: 700,
});

export const backLoginBtn = css({
  fontSize: "14px",
  lineHeight: "21.6px",
});
