import useAction from "../../hooks/useAction";
import { useTranslation, withTranslation } from "next-i18next";
import { Grid, Link } from "@mui/material";
import { Box } from "@mui/system";
import Text from "@ds/components/Text";
import { LayoutProps } from "@layouts/interface";
import { titleText } from "@layouts/Auth/styles";
import AuthHeader from "../../../../fragments/AuthHeader";
import { logo } from "@config/images";
import { useDispatch } from "react-redux";
import { setCloseSnackbar } from "@redux/slices/rootSnackbar";

const Mobile: React.FC<LayoutProps> = ({ children }) => {
  const { router, pageText, backToLogin } = useAction();
  const dispatch = useDispatch();
  const { t } = useTranslation("login");
  return (
    <>
      <AuthHeader goBack onBack={() => {
        dispatch(setCloseSnackbar());
        router.back();
      }} image={<img src={logo} width="64px" height="27px" />} />
      <Grid
        container
        direction="column"
        spacing={0}
        pt="70px"
        px="16px"
        sx={{ minHeight: "100vh", backgroundColor: "white" }}
      >
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}
        >
          <Box width="100%" mb="16px">
            <Box my="12px">
              <Text css={titleText}>{pageText.textTitle}</Text>
            </Box>
            <Box>
              <Text color="general-mid" variant="body1">
                {pageText.textDesc}
              </Text>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt="5px">{children}</Box>
        </Grid>
        {router.pathname.includes("/confirm-username") && (
          <Grid item xs={12}>
            <Link
              onClick={(e: React.MouseEvent<HTMLElement>) => backToLogin(e, "/login")}
              sx={{ textDecoration: "none" }}
            >
              <Text clickable="true" color="primary-main" variant="body2">
                {t("back")}
              </Text>
            </Link>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default withTranslation(["login"])(Mobile);
