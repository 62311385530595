import React from "react";
import { useTranslation, withTranslation } from "next-i18next";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ButtonHelp from "@atoms/ButtonHelp";
import { logo } from "@config/images";
import Text from "@ds/components/Text";
import type { LayoutProps } from "@layouts/interface";
import useAction from "../../hooks/useAction";
import { imageBackground, titleText } from "../../styles";
import DynamicImage from "@ds/components/DynamicImage";

const Desktop: React.FC<LayoutProps> = ({ children }) => {
  const { router, pageText, backToLogin } = useAction();
  const { t } = useTranslation("login");
  return (
    <Grid container>
      <Grid item xs={3} css={imageBackground} />
      <Grid item xs={9} css={{ backgroundColor: "white" }}>
        {router.pathname.includes("/login") && (
          <Link href="/" sx={{ textDecoration: "none", position: "fixed" }}>
            <Box display="flex" alignItems="center" gap={1} pt={5} pl={4}>
              <KeyboardBackspaceIcon color="primary" />
              <Text weight="700" color="primary-main">
                {t("backToHome")}
              </Text>
            </Box>
          </Link>
        )}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          spacing={0}
          sx={{ minHeight: "100vh" }}
        >
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}
          >
            <Box width={328} textAlign="center" mb="16px" mt="12px">
              <DynamicImage src={logo} alt="MyTEnS" width={133} height={56} />
              <Box my="12px">
                <Text css={titleText}>{pageText.textTitle}</Text>
              </Box>
              <Box>
                <Text color="general-mid" variant="body1">
                  {pageText.textDesc}
                </Text>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mt="5px">{children}</Box>
          </Grid>
          {router.pathname.includes("/confirm-username") && (
            <Grid item xs={12}>
              <Link
                onClick={(e: React.MouseEvent<HTMLElement>) => backToLogin(e, "/login")}
                sx={{ textDecoration: "none" }}
              >
                <Text clickable="true" color="primary-main" variant="body2">
                  {t("back")}
                </Text>
              </Link>
            </Grid>
          )}
        </Grid>
        <ButtonHelp />
      </Grid>
    </Grid>
  );
};

export default withTranslation(["login"])(Desktop);
