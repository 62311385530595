import { logScreen } from "@helpers/firebase/analytics";
import { getOTPData, getStorageToken } from "@helpers/storage";
import useResponsive from "@helpers/useResponsive";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

const useAction = () => {
  const router = useRouter();
  const mdClient = useResponsive("md");
  const usernameForgotPass: string = getOTPData()?.identifier;
  const { t } = useTranslation("login");

  const [pageText, setPageText] = useState({
    textTitle: "",
    textDesc: "",
  });

  useEffect(() => {
    logScreen(router.asPath);
  }, [router.asPath]);

  useEffect(() => {
    if (!router.asPath.includes("/lupa-password")) {
      if (
        getStorageToken().accessToken ||
        (router.asPath.includes("konfirmasi-otp") && !usernameForgotPass) ||
        (router.asPath.includes("konfirmasi-password-baru") && !usernameForgotPass)
      ) {
        router.push("/dashboard");
      }
    }
  }, []);

  useEffect(() => {
    switch (router?.pathname) {
      case "/login":
        setPageText({
          textTitle: t("title"),
          textDesc: t("subtitle"),
        });
        break;
      case "/lupa-password/konfirmasi-username":
        setPageText({
          textTitle: t("forgotTitle"),
          textDesc: t("forgotDesc"),
        });
        break;
      case "/lupa-password/konfirmasi-otp":
        setPageText({
          textTitle: t("input_otp"),
          textDesc: "",
        });
        break;
      case "/lupa-password/konfirmasi-password-baru":
        setPageText({
          textTitle: t("new_password"),
          textDesc: "",
        });
        break;
    }
  }, []);

  const backToLogin = (e: React.MouseEvent<HTMLElement>, path: string) => {
    e.preventDefault();
    router.push(path);
  };

  return {
    router,
    mdClient,
    pageText,
    backToLogin,
  };
};

export default useAction;
