import { makeStyles } from "@mui/styles";
import color from "@definitions/mui/color";
import { logo } from "@config/images";

const useStyles = makeStyles(() => ({
  headerWrapper: {
    boxShadow: "0px 6px 12px rgba(46, 67, 77, 0.1)",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    padding: "16px 16px 0px 16px",
    zIndex: 10,
    backgroundColor: color.white,
    background: `url(${logo})`,
    backgroundPosition: "center",
    backgroundSize: "64px 27px",
    backgroundRepeat: "no-repeat"
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 16,
  },
  clickableIcon: {
    marginRight: 20,
    color: color.general.main,
    display: "flex",
    alignItems: "center",
  },
}));

export default useStyles;