import { Box } from "@mui/system";
import { useRouter } from "next/router";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import useStyles from "./styles";

interface IAuthHeader {
  image?: React.ReactNode;
  goBack?: boolean;
  onBack?: () => void;
}

const AuthHeader = ({ goBack, onBack }: IAuthHeader) => {
  const router = useRouter();
  const classes = useStyles();

  const _onBack = () => {
    onBack ? onBack() : router.back();
  };

  return (
    <Box className={classes.headerWrapper}>
      <Box className={classes.header}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box sx={{ display: "flex" }}>
            {goBack && (
              <Box onClick={_onBack} className={classes.clickableIcon} data-testid="back-button">
                <KeyboardBackspaceIcon
                  sx={{ fontSize: 40, width: "24px !important", height: "auto !important" }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AuthHeader;
